@keyframes _show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.nivo-line-chart-points svg > g > g:nth-last-of-type(3),
.nivo-line-chart-separate-points svg > g > g:nth-last-of-type(8) {
  transform: translate(0, calc(100% - 55px)) !important;
}
.nivo-line-chart-points svg > g > g:nth-last-of-type(3) text,
.nivo-line-chart-separate-points svg > g > g:nth-last-of-type(8) text {
  font-weight: 600;
}
.nivo-line-chart-points {
  width: calc(100% + 40px);
  margin-right: -40px;
}
.nivo-line-chart-points path,
.nivo-line-chart-separate-points path,
.nivo-line-chart-points g > line,
.nivo-line-chart-separate-points g > line {
  transition: stroke 0.5s ease;
}
.nivo-line-chart-points svg > g > g:not(.def-opacity-0),
.nivo-line-chart-separate-points svg > g > g:not(.def-opacity-0) {
  animation: _show 1s;
  opacity: 1;
}
.line-chart-tooltip-info,
.line-chart-tooltip-value {
  background-color: white;
  padding: 2px 10px;
  border-radius: 2px;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.25));
}
.line-chart-tooltip-info:before,
.line-chart-tooltip-value:before {
  content: '';
  display: block;
  position: absolute;
  bottom: -11px;
  width: 12px;
  height: 12px;
  background-color: inherit;
  transform: rotate(45deg) translate(-70%, 0);
  left: 50%;
}
.line-chart-tooltip-info span,
.line-chart-tooltip-value span {
  color: #00205B;
  font-size: 14px;
}
.line-chart-tooltip-info span:first-child,
.line-chart-tooltip-value span:first-child {
  font-weight: 400;
}
.line-chart-tooltip-info span:last-child,
.line-chart-tooltip-value span:last-child {
  font-weight: 600;
}
.line-chart-tooltip-info {
  background-color: #00205B;
  display: none;
  width: fit-content;
  position: absolute;
  max-width: 300px;
  text-align: center;
  color: #FFFFFF;
  filter: none;
  transform: translate(-20%, -50%);
  transition: 1s ease;
}
.line-chart-legend-square {
  height: 16px;
  border-radius: 3px;
}
.line-chart-legend-line {
  border-radius: 4px;
  height: 4px;
}
.line-chart-legend-gusty_line:before {
  float: left;
  content: '';
  border-radius: 4px;
  height: 4px;
  width: 7px;
  background: linear-gradient(90deg, rgba(54, 113, 233, 0.51) 0%, rgba(54, 113, 233, 0.724) 100%);
}
.line-chart-legend-gusty_line:after {
  float: right;
  content: '';
  border-radius: 4px;
  height: 4px;
  width: 7px;
  background: linear-gradient(90deg, rgba(54, 113, 233, 0.786) 0%, #3671e9 100%);
}
.line-chart-legend-circle {
  border-radius: 50%;
  height: 16px;
}
.line-chart-legend-square,
.line-chart-legend-line,
.line-chart-legend-gusty_line,
.line-chart-legend-circle {
  width: 16px;
  margin-right: 10px;
}
.line-chart-legend-square ~ div > span,
.line-chart-legend-line ~ div > span,
.line-chart-legend-gusty_line ~ div > span,
.line-chart-legend-circle ~ div > span {
  line-height: 100%;
}
line ~ .show-line {
  opacity: 0;
}
#prediction_fill {
  fill: url(#prediction_gradient);
}
#prediction_gradient stop:first-child {
  stop-color: #0000FFCC;
}
#prediction_gradient stop:last-child {
  stop-color: #0000FF00;
}
